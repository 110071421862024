<template>
  <div class="page page__content-wrapper">
    <div class="page__full-width logo-top-slider">
      <div class="page__logo-slider-wrapper">
        <LogoSlider v-bind:slides="bgSlides"/>
      </div>
      <div class="page__link-block download-block">
        <a :href="item.urlZip"
           @click.prevent="downloadItemZip(item)"
           class="btn__circle btn__nav-slider btn-download">
          <img src="~assets/img/icons/icon-zip.svg" alt="ZIP">
        </a>
        <a :href="item.url"
           @click.prevent="downloadItem(item)"
           class="btn__circle btn__nav-slider  btn-download">
          <img src="~assets/img/icons/icon-ai.svg" alt="AI">
        </a>
        <a :href="item.urlZipPng"
           @click.prevent="downloadItemZipPng(item)"
           class="btn__circle btn__nav-slider btn-download">
          <img src="~assets/img/icons/icon-png.svg" alt="PNG">
        </a>
      </div>
    </div>
    <div class="page__min-container">
      <div class="page__text-block">
        <div class="text__head">
          Построение логотипа
        </div>
        <div class="text__normal">
          Логотип всегда берётся из готового файла, а не строится с нуля. Тем не менее, сетка построения может
          пригодиться в различных прикладных ситуациях, — например, для анимации или увеличения детализации 3D-модели.
        </div>
      </div>
    </div>

    <div class="page__min-container">
      <div class="page__img-wrapper">
        <img src="~assets/img/logo-page/elements-logo.svg" alt="Елименты логотипа">
      </div>
    </div>

    <div class="page__min-container ">
      <div class="page__text-block">
        <div class="text__head">
          Охранное поле
        </div>
        <div class="text__normal">
          Минимальный запас пространства, необходимый вокруг логотипа. Этот запас также необходимо соблюдать при
          размещении логотипа вплотную к краям макета.
        </div>
      </div>

    </div>
    <div class="page__min-container">
      <div class="page__img-wrapper ">
        <img src="~assets/img/logo-page/min-margin-logo.svg" alt="Минимальный запас пространства">
      </div>
    </div>
    <div class="page__min-container">
      <div class="page__text-block">
        <div class="text__head">
          Монохром
        </div>
        <div class="text__normal">
          Монохромная версия логотипа используется только в случае физической невозможности реализовать цветную версию
          на носителе.
        </div>
      </div>

    </div>
    <div class="page__min-container img-wrapper-full">
      <div class="page__img-wrapper ">
        <img src="~assets/img/logo-page/monochrom-logo.svg" alt="Монохром">
      </div>
    </div>

    <div class="page-nav__tablet">
      <router-link class="next-page__link" to="/identity/color">
        Цвета
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
    <div class="next-page">
      <router-link class="next-page__link" to="/identity/color">
        Цвета
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "src/assets/style/var-style";
@import "src/assets/style/text-style";
@import "src/assets/style/page-block";
@import "src/assets/style/buttons";

.page {
  margin-bottom: 188px;
  &__logo-slider-wrapper {
    margin-bottom: 104px;
  }

  &__min-container {
    .text__head {
      font-weight: 500;
    }
    &:nth-child(4) {
      margin-bottom: 18px;
    }
    &:nth-child(5) {
      margin-bottom: 63px;
    }

    &:nth-child(6) {
      margin-bottom: 47.5px;
    }
    .page__text-block {
      .text__head:nth-child(1) {
        line-height: 41px;
        margin-bottom: 8.8px;
        letter-spacing: 0.1px;
      }
    }

    .page__img-wrapper {
      margin-bottom: 59px;
    }
  }
}

.logo-top-slider {
  display: flex;
  align-items: center;
}

.page__link-block {
  display: flex;
}

.logo-top-slider {
  .download-block {
    position: relative;
    bottom: 85px;
  }
}

.page__link-block.download-block {
  margin-left: 22px;
}

.page__img-wrapper-mobile {
  display: none;
}

@media screen and (max-width: 1540px) {

  .page__content-wrapper {
    padding-left: 64px;
  }
}
@media screen and (max-width: 1456px) {
  .page {
    margin-bottom: 204px;

    .page-nav__tablet .next-page__link {
      width: 100%;
    }
  }
}


@media screen and (max-width: 1320px) {

  .page__content-wrapper {
    padding-left: 0;
  }
}
@media screen and (max-width: 1266px) {
  .page__link-block.download-block {
    margin-left: -4px;
  }
}

@media screen and (max-width: 1216px) {
  .page__logo-slider-wrapper,
  .page__full-width.logo-top-slider {
    width: 100%;
  }
  .page__link-block {
    display: none;
  }
  .page__link-block.download-block {
    display: none;
  }
  .logo-top-slider {
    max-width: 832px;
    margin-right: auto;
    margin-left: auto;
  }
  .logo-page__min-slider {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .img-wrapper-full {
    width: 100%;
    min-width: 100%;
  }
}

@media screen and (max-width: 896px) {
  .pages-wrap {
    .container {
      .page {
        &.page__content-wrapper {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }
  .logo-page__min-slider {
    margin: 30px auto;
    width: fit-content;
  }
  .logo-page__bottom-slider,
  .page__logo-slider-wrapper {
    margin: 30px auto 0;
  }
  .logo-page__bottom-slider {
    width: fit-content;
  }
  .page__full-width.logo-top-slider {
    margin-bottom: 120px;
  }
  .text__head {
    margin-bottom: 24px;
  }
  .page__min-container {
    margin-bottom: 32px;
  }
  .page__img-wrapper.desc {
    img {
      display: none;
    }
  }
  .page__img-wrapper.desc .page__img-wrapper-mobile {
    display: flex;
    flex-direction: column;

    img {
      width: 100%;
      margin-bottom: 32px;
      display: inherit;
    }
  }
}
</style>
<script>
import LogoSlider from "@/components/Sliders/LogoSlider";
import Axios from 'axios';

export default {
  components: {
    LogoSlider
  },
  data() {
    return {
      bgSlides: [
        {
          bgColor: '#000033',
          imgSlide: '/img/slides/logo-blue.svg',
          isChecked: false
        },
        {
          bgColor: '#E6175C',
          imgSlide: '/img/slides/logo-white-christ.svg',
          isChecked: false
        },
        {
          bgColor: '#FFF',
          imgSlide: '/img/slides/logo.svg',
          isChecked: false
        },
        {
          bgColor: '#F2F5F5',
          imgSlide: '/img/slides/logo.svg',
          isChecked: true
        },
      ],
      item: {
        url: '../dowload-file/Logo/GDP-Logo-AI.zip',
        urlZip: '../dowload-file/Logo/GDP-Logo-All.zip',
        urlZipPng: '../dowload-file/Logo/GDP-Logo-PNG.zip'
      }
    }
  },
  methods: {
    downloadItem({url}) {
      Axios.get(url, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/zip'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'logo'
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
    },
    downloadItemZip({urlZip}) {
      Axios.get(urlZip, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/zip'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'logo'
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
    },
    downloadItemZipPng({urlZipPng}) {
      Axios.get(urlZipPng, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/zip'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'logo'
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
    },
  }
}
</script>
