<template>
  <div class="slider-logo">
    <div class="slider-logo__slides-block">
      <div class="slider-logo__slides-wrap"
           v-bind:style="{left: this.leftPos? this.leftPos: '0'}">
        <div class="slider-logo__slides-item"
             v-for="(slide, i) of slides"
             :key="i"
             v-bind:style="{background: slide.bgColor?slide.bgColor:''}"
             v-bind:class="[slide.isChecked ? 'active' : '']">
          <img v-bind:src="slide.imgSlide" :alt="slide.bgColor">
        </div>
      </div>
    </div>
    <div class="slider-logo__nav-block">
      <button class="btn btn__circle"
              v-for="(slide, i) of slides"
              :key="i"
              @click="checkSlide(i)"
              v-bind:class="[slide.isChecked ? 'active' : '']">
        <span class="btn__circle-color" v-bind:style="{background: slide.bgColor}">
        </span>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: ['slides'],
  data(){
    return{
      leftPos: 0,
      widthWindow: 100,
      widthSlideBlock: 832
    }
  },
  mounted() {
    this.leftPos = '-'+ this.slides.findIndex( item => item.isChecked)*833 + 'px';
    this.updateWidth()
    this.checkSlide(this.slides.length -1)
  },
  created() {
    window.addEventListener('resize', this.updateWidth);
  },
  methods:{
    updateWidth() {
      this.widthWindow = window.innerWidth;
      if(this.widthWindow < 896){
        this.widthSlideBlock = 344
      } else if(this.widthWindow < 1090) {
        this.widthSlideBlock = 640
      } else {
        this.widthSlideBlock= 832
      }
    },
    checkSlide(index){
      this.slides.map((item, i) => i === index? item.isChecked = true: item.isChecked = false)
      this.leftPos = index !== 0 ? '-'+ this.widthSlideBlock*index + 'px': '0';
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/style/var-style";
@import "src/assets/style/buttons";

.slider-logo{
  display: flex;
  flex-direction: column;
  max-width: 832px;
  width: 100%;

  &__slides-block{
    overflow: hidden;
    position: relative;
  }
  &__nav-block{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  }
  &__slides-wrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    left: 0;
    transition: left .3s ease-in-out;
  }
  &__slides-item{
    min-height: 480px;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 560px;
    }
  }
}
//@media screen and (max-width: 1000px){
//  .slider-logo{
//    width: 832px;
//  }
//}
@media screen and (max-width: 1090px){
  .slider-logo,
  .slider-logo__slides-block,
  .slider-logo__slides-item,
  .slider-logo{
    width: 640px;
  }
  .slider-logo{
    margin: 0 auto;
  }
  .slider-logo__slides-item{
    img{
      width: 100%;
      max-width: 344px;
    }
  }
}
@media screen and (max-width: 896px) {
  .slider-logo,
  .slider-logo__slides-block,
  .slider-logo__slides-item,
  .slider-logo{
    max-width: 344px;
    width: 100%;
  }
  .slider-logo__slides-item{
    height: 172px;
    min-height: 172px;
    img{
      width: 100%;
      max-width: 235px;
    }
  }
}
</style>
